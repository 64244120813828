const { forEach } = require("lodash");

Eloca.behaviors.default = function (context) {
    //scroll menu
    scrollFunction(context);
    window.onscroll = function () {
        scrollFunction(context);
    };

    if ($("#modalRegioes", context).length && $("#modalRegioes").hasClass("js-start-modal")) {
        const modal = new bootstrap.Modal(document.getElementById("modalRegioes"));
        modal.show();
    }

    // Sidebar
    $(".js-sidebar-close, #overlayer", context).on("click", function (e) {
        e.preventDefault();
        $(".sidebar-container", context).removeClass("open-block");
        $("#overlayer", context).removeClass("show-overlayer");
        $("body, html", context).css("overflow", "auto");
    });

    // Sidebar landing page
    $(".lp-mobile-menu a", context).on("click", function () {
        $(".sidebar-container", context).removeClass("open-block");
        $("#overlayer", context).removeClass("show-overlayer");
        $("body, html", context).css("overflow", "auto");
    });

    // Open sidebar
    $(".js-sidebar-open, .js-lp-menu-open", context).on("click", function (e) {
        $(".sidebar-container").addClass("open-block");
        $("#overlayer").addClass("show-overlayer");
        $("body, html").css("overflow", "hidden");
    });

    // Lojas Eloca
    $(".js-eloca-sidebar", context).on("click", function (e) {
        $(".sidebar-container").toggleClass("open-block");
        $("#overlayer__eloca").toggleClass("show-overlayer");
        $(".js-icon-close-menu").toggleClass("d-none");
        $(".js-icon-menu").toggleClass("d-none");
    });
    $(".js-aba-produtos", context).on("click", function (e) {
        $("#aba-produtos").toggleClass("d-block");
    });
    $(".js-aba-conteudos", context).on("click", function (e) {
        $("#aba-conteudos").toggleClass("d-block");
    });

    // Scroll landing page
    $("#navbarLP a", context).on("click", function (e) {
        e.preventDefault();
        let headerHeight = $("header").outerHeight();
        let target = $(this).attr("href");
        $("html, body").animate(
            {
                scrollTop: $(target).offset().top - headerHeight,
            },
            200
        );
    });

    // Search - Modal
    $(".js-search-close, #overlayer", context).on("click", function (e) {
        e.preventDefault();
        $(".search-container").removeClass("open-block");
        $("#overlayer").removeClass("show-overlayer");
    });

    $(".js-search-open", context).on("click", function (e) {
        $(".search-container").addClass("open-block");
        $("#overlayer").addClass("show-overlayer");
    });

    $(".js-produtos-ordem", context).on("change", function () {
        $(this).closest("form").trigger("submit");
    });

    // Modal - Galeria de Produtos com Zoom
    $("#productDetailsGalleryZoom .carousel-inner", context).on(
        "click",
        function () {
            $("#productDetailsPhotos", context).modal("hide");
        }
    );

    $(".js-modal-video", context).on("hidden.bs.modal", function () {
        $("iframe", this).attr("src", $("iframe", this).attr("src"));
    });

    $("iframe", context).each(function () {
        if ($(this).attr("data-src")) {
            $(this).attr("src", $(this).attr("data-src"));
        }
    });

    // Placeholder - Produto
    $(".js-img-loading", context).each(function (index) {
        if ($(this).hasClass("img-fluid")) {
            const box = $(this).closest(".js-card-produto");

            $(this).on("load", function () {
                removeLoading(box);
            });

            setTimeout(() => {
                removeLoading(box);
            }, 1200);
        } else {
            const box = $(this).closest(".js-card-produto");
            removeLoading(box);
        }
    });

    // Placeholder - Banner
    $(".js-banner-loading", context).each(function (index) {
        const box = $(this).closest(".js-card-banner");

        $(this).on("load", function () {
            removeLoadingBanner(box);
        });

        setTimeout(() => {
            removeLoadingBanner(box);
        }, 1000);
    });

    /* Custom radio Button */
    $(".js-custom-radio-option", context).on("change", function () {
        let formCheck = $(this).closest(".js-form-check");
        $("div.card-selected", formCheck).removeClass("card-selected");
        $(this, formCheck)
            .closest(".checkout__radio")
            .addClass("card-selected");
    });

    // Faixas Produto - Input
    $(".js-radio-selected", context).on("change", function () {
        let miniBloco = $(this).closest(".js-mini-bloco");
        let faixas = $(this).closest(".js-bloco-faixas");
        $("div.option-selected").not('.custom').removeClass("option-selected");
        $(this, miniBloco)
            .closest(".js-mini-bloco")
            .addClass("option-selected");
    });

    $(".js-select-estados", context).on("change", function (e) {
        $.ajax({
            url: siteUrl(`/cidades/busca/${this.value}`),
            type: "get",
            success: function (res) {
                $(".js-select-cidades option", context).remove();
                res.forEach(function (item) {
                    $(".js-select-cidades", context).append(
                        `<option value="${item.id}">${item.value}</option>`
                    );
                });
            },
            error: function (err) {
                console.log("err", err);
            },
        });
    });

    $(".js-cliente-estrangeiro", context).on("change", function () {
        if ($(this).is(":checked")) {
            $(".js-input-documento", context).removeClass("d-none");
            $(".js-input-cpf", context).addClass("d-none");
        } else {
            $(".js-input-documento", context).addClass("d-none");
            $(".js-input-cpf", context).removeClass("d-none");
        }
    });

    $(".js-busca-cep", context).on("blur", function (e) {
        const cep = this.value;
        if (cep.length < 9) {
            return;
        }

        $("#overlayer-loading").addClass("show-overlayer");

        $.ajax({
            url: siteUrl(`/cidades/cep/${cep}`),
            type: "get",
            success: function (res) {
                if (!res.error && res.dados) {
                    const { busca, cidades, estado_id, cidade_id } = res.dados;
                    $("#rua", context).val(busca.logradouro);
                    $("#bairro", context).val(busca.bairro);

                    if (estado_id) {
                        $(".js-select-estados", context).val(estado_id);
                        $(".js-select-cidades option", context).remove();
                        cidades.forEach(function (item) {
                            $(".js-select-cidades", context).append(
                                `<option value="${item.id}">${item.value}</option>`
                            );
                        });
                        if (cidade_id) {
                            $(".js-select-cidades", context).val(cidade_id);
                        }
                    }
                    $("#numero", context).trigger("focus");
                }
                $("#overlayer-loading").removeClass("show-overlayer");
            },
        });
    });

    if (
        $(".js-busca-cep.novo-endereco", context).length &&
        $(".js-busca-cep.novo-endereco", context).val()
    ) {
        setTimeout(function () {
            $(".js-busca-cep.novo-endereco", context).trigger("blur");
        }, 500);
    }

    $(".js-show-loading", context).on("click", function () {
        $(this).attr("disabled", "disabled");
        $("#overlayer-loading").addClass("show-overlayer");
    });

    $(".js-form-prevent-resend", context).on("submit", function () {
        $("#overlayer-loading").addClass("show-overlayer");
        $("button", this).attr("disabled", "disabled");
    });

    // Slider Marcas
    $(".js-slider-scroll-prev", context).on("click", function () {
        let carousel = $(this).closest(".items-carousel-block");
        let cords = $(".items-carousel", carousel).offset();
        let cordsContent = $(".carousel-content").offset();
        if (cords.left < cordsContent.left) {
            let movimento = 300;
            if (cords.left + movimento > cordsContent.left) {
                movimento = cordsContent.left - cords.left;
            }
            $(".items-carousel", carousel).offset({
                top: cords.top,
                left: cords.left + movimento,
            });
        }
    });
    $(".js-slider-scroll-next", context).on("click", function () {
        let carousel = $(this).closest(".items-carousel-block");
        let cords = $(".items-carousel", carousel).offset();
        let cordsContent = $(".carousel-content").offset();

        let deslocamento =
            $(".items-carousel", carousel).width() -
            $(".carousel-content").width();

        if (deslocamento > 0 && cords.left > cordsContent.left - deslocamento) {
            let movimento = 300;
            if (cords.left - movimento < cordsContent.left - deslocamento) {
                movimento = deslocamento - (cordsContent.left - cords.left);
            }
            $(".items-carousel", carousel).offset({
                top: cords.top,
                left: cords.left - movimento,
            });
        }
    });

    // Menu Scroll
    $(".navbar--block", context).on("click", "a[href*='#']", function (e) {
        e.preventDefault();
        const href = this.href;
        const urlAtual = window.location.href;
        const check = href.slice(0, href.indexOf("#"));

        if (urlAtual != check) {
            window.location.href = href;
        }
        var targetOffset = $(this.hash).offset()
            ? $(this.hash).offset().top
            : 0;
        var customOffset = 200;
        $("html, body").animate(
            { scrollTop: targetOffset - customOffset },
            500
        );
    });

    $(".js-btn-cookies", context).on("click", function (e) {
        const link = $(this).attr("data-href");
        $.get({
            url: link,
            type: "get",
            success: function (response) {
                $(".js-cookies-modal").remove();
            },
        });
    });

    // Pix
    $(".js-copy-clipboard", context).on("click", function () {
        copyTextToClipboard($(this).data("text"), this);
        $(this).html('<span class="text-success">Copiado!</span>');
    });

    // Input inválido
    $("input.form-control.is-invalid", context).on("focus", function () {
        $(this).removeClass("is-invalid");
    });

    $('.js-click-video', context).on('click', function () {
        if (window.isProduction) {
            gtag("event", "clique", {
                event_category: "clique",
                event_label: $(this).data("event-label"),
                event_action: "clique",
            });
        }
    });
    $('.js-keyup-form-event', context).on('keyup', function () {
        if (window.isProduction && $(this).val().length == 1) {
            gtag("event", "form", {
                event_category: "form",
                event_label: $(this).data("event-label"),
                event_action: "form",
            });
        }
    });

    $(".js-select-pais", context).on("click", function () {
        const box = $(this).closest(".input-group");
        $(".js-dropdown-telefone", box).html($(this).html());
        $(".js-ddi-telefone", box).val($(this).data("pais-ddi"));
        $(".js-pais-telefone", box).val($(this).data("pais-id"));
        $(".js-dropdown-telefone", box).data("mascara", $(this).data('mascara'));
        $(".js-dropdown-telefone", box).trigger("change");
    });

    function removeLoading(context) {
        $(".js-loading-produto", context).remove();
        $(".card.product", context).removeClass("d-none").addClass("animated");
    }

    function removeLoadingBanner(context) {
        $(".js-loading-banner", context).remove();
        $(".banner-block", context).removeClass("d-none");
    }

    // Animacao ao dar scroll
    function reveal(context) {
        $(".reveal", context).each(function () {
            let windowHeight = window.innerHeight;
            let elementTop = $(this)[0].getBoundingClientRect().top;
            let elementVisible = 150;
            if (elementTop < windowHeight - elementVisible) {
                $(this, context).addClass("active");
            }
        });
    }

    function scrollFunction(context) {
        if (
            document.body.scrollTop > 38 ||
            document.documentElement.scrollTop > 38
        ) {
            $(".js-top-fixed").addClass("onscroll");
            $(".js-lp-fixed").addClass("boxshadow");
            $(".back-to-top").removeClass("d-none");
        } else {
            $(".js-top-fixed").removeClass("onscroll");
            $(".js-lp-fixed").removeClass("boxshadow");
            $(".back-to-top").addClass("d-none");
        }
        reveal(context);
    }

    function fallbackCopyTextToClipboard(text, element) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        if ($(element).closest(".modal").length) {
            let modal = $(element).closest(".modal");
            $(modal).append(textArea);
        } else {
            $("body").append(textArea);
        }
        textArea.focus();
        textArea.select();

        try {
            let copy = document.execCommand("copy");
        } catch (err) {
            //console.error("Fallback: Oops, unable to copy", err);
        }

        $(textArea).remove();
    }
    function copyTextToClipboard(text, element) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text, element);
            return;
        }
        navigator.clipboard.writeText(text);
    }

    $(".js-input-cpf-cliente", context).on("change", function () {
        const valor = $(this).val();
        if (!isValidCPF(valor)) {
            $("#cpf-invalido", context).html("CPF Invalido!");
            if (!$(this).hasClass("is-invalid")) {
                $(this).addClass("is-invalid");
            }
        } else {
            $("#cpf-invalido", context).html("");
            if ($(this).hasClass("is-invalid")) {
                $(this).removeClass("is-invalid");
            }
        }
    });

    $(".js-input-cnpj-cliente", context).on("change", function () {
        const valor = $(this).val();
        if (!isValidCNPJ(valor)) {
            $("#cnpj-invalido", context).html("CPF Invalido!");
            if (!$(this).hasClass("is-invalid")) {
                $(this).addClass("is-invalid");
            }
        } else {
            $("#cnpj-invalido", context).html("");
            if ($(this).hasClass("is-invalid")) {
                $(this).removeClass("is-invalid");
            }
        }
    });

};
